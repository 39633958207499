/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "react-router-dom";
import { EntypoChevronLeft } from "react-entypo";

function AcademyRecords(props) {
    return (
        <div className="work-wrapper">
            <div className="work-header" style={{ flexDirection: 'column' }}>
                <div className="work-header-text" style={{ marginBottom: '2rem' }}>
                    <Link to="/" className="back-link" sx={{ color: 'gray', marginLeft: '-.75em' }}>
                        <EntypoChevronLeft /><h4 sx={{ margin: 0, fontWeight: 400 }}>Back</h4>
                    </Link>
                </div>
                <div className="work-banner-image" style={{backgroundImage: `url('/img/work/ar/ar-1.png')`, backgroundPosition: '50% 50%'}}></div>
            </div>
            <div className="work-header">
                <div className="work-header-text" sx={{display: ['block','block','grid'], gap: '2rem', width: ['100%','100%','100%'], grid: 'auto-flow / 65% 35%'}}>
                    <div sx={{gridColumn: 1, minWidth:'8rem', maxWidth: '25rem'}}>
                        <h3 sx={{ marginTop: '2em' }}>Academy Records & CDs</h3>
                        <p>2022, <i>Work in Progress</i><br /><br/><br/>
                        <b>Academy Records & CDs</b> is a staple in New York City when it comes to second-hand records, CDs, and DVDS. Founded in 1971, the store has initially began as a second-hand bookstore, and transitioned into a record store later on.
                                <br/><br/>
                            Located in the heart of Manhattan on 18th Street near Union Square, the store is an attraction to locals and travellers alike. After the establishment of its original website in the 1990s, the owner wanted to revamp the store's online presence, where X Fusor Design Labs set out to craft the visual identity of the store for the decades to come.
                        </p><br/>
                        <br/>
                    </div>
                    <p sx={{gridColumn: [1,2,2], marginTop: ['-2rem','9.75rem','9.75rem'], minWidth: '5rem'}}>
                        <b>Team Members</b><br/><br/>
                        <i>
                            Yicheng Feng<br/>
                            William Stark<br/>
                            Susan Su
                        </i>
                        <br/><br/>
                        <b>Project Scope</b><br/>
                        <div sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(1, minmax(0, 1fr))', gap: '.1rem', marginTop: '1rem' }}>
                            <span>Branding</span>
                            <span>Graphics Design</span>
                            <span>Web Design</span>
                        </div>
                    </p>
                </div>
                {/* <div className="work-body-image work-image-tile" sx={{backgroundImage: `url('/img/work/eclair/eclair-1.jpg')`, marginTop: '3rem', marginRight: 0, padding: '0', width: ['100%','100%','49%'], height: ['25rem','25rem','30rem'], backgroundSize: 'fit', backgroundRepeat: 'no-repeat', backgroundPositionX: '35%', backgroundPositionY: '60%'}}></div> */}
            </div>
            <div sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], gap: '0', width: '100%', marginTop: '3em', marginBottom: '3rem' }}>
                <div style={{margin: 0, padding: 0}}><img src="/img/work/ar/ar-2.png" sx={{ width: '100%', height: '100%', borderRadius: '.5em', margin: 0, padding: 0 }}></img></div>
            </div>
            <div sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], gap: '0', width: '100%', marginTop: '3em', marginBottom: '3rem' }}>
                <div style={{margin: 0, padding: 0}}><img src="/img/work/ar/ar-3.png" sx={{ width: '100%', height: '100%', borderRadius: '.5em', margin: 0, padding: 0 }}></img></div>
            </div>
            
            {/* <div style={{ width: `calc(100% + 2em)`, }}>
                <div className="work-body-image work-image-tile" style={{ backgroundImage: `url('/img/work/aspecta/aspecta-5.jpg')` }}></div>
                <div className="work-body-image work-image-tile" style={{ backgroundImage: `url('/img/work/aspecta/aspecta-7.jpg')`, backgroundPositionX: '47.5%'}}></div>
            </div> */}
            {/* <div className="work-body-image work-image-tile" style={{ backgroundImage: `url('/img/work/eclair/eclair-2.jpg')`, marginBottom: '8rem', width: '100%', marginRight: 0, height: ['20rem','20rem','50em'], backgroundPositionY: '40%' }}></div> */}
            
        </div>
    )
}

export default AcademyRecords;