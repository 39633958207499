/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "react-router-dom";
import { EntypoChevronLeft } from "react-entypo";

function aspecta(props) {
    return (
        <div className="work-wrapper">
            <div className="work-header" style={{ flexDirection: 'column' }}>
                <div className="work-header-text" style={{ marginBottom: '2rem' }}>
                    <Link to="/" className="back-link" sx={{ color: 'gray', marginLeft: '-.75em' }}>
                        <EntypoChevronLeft /><h4 sx={{ margin: 0, fontWeight: 400 }}>Back</h4>
                    </Link>
                </div>
                <div className="work-banner-image" style={{backgroundImage: `url('/img/work/aspecta/aspecta-2.jpg')`, backgroundPosition: '50% 50%'}}></div>
            </div>
            <div className="work-header">
                <div className="work-header-text" sx={{display: ['block','block','grid'], gap: '2rem', width: ['100%','100%','50%'], grid: 'auto-flow / 65% 35%'}}>
                    <div sx={{gridColumn: 1, minWidth:'8rem', maxWidth: '25rem'}}>
                        <h3 sx={{ marginTop: '2em' }}>Aspecta</h3>
                        <p>2022, <i>Work in Progress</i><br /><br/><br/>
                        <b>Future paradigm of work and professional lives<br/>powered by AI-driven web<br/>and on chain data insights.</b><br/><br/>
                        Comprehensive branding project for a Web3-based social profile platform Aspecta. Aspecta’s AI solution portrays talents with web and on-chain data to build the future paradigm for recruiting, working, and professional lives. </p><br/>
                        <b>Project Scope</b><br/>
                        <div sx={{ width: '100%', display: 'grid', gridTemplateColumns: ['repeat(1, minmax(0, 1fr))', 'repeat(1, minmax(0, 1fr))', 'repeat(2, minmax(0, 1fr))'], gap: '.1rem', marginTop: '1rem' }}>
                            <span>Branding</span>
                            <span>Graphics Design</span>
                            <span>Web Design</span>
                            <span>Typography</span>
                        </div>
                        <br/>
                        <br/><br/>
                    </div>
                    <p sx={{gridColumn: [1,2,2], marginTop: ['-2rem','9.75rem','9.75rem'], minWidth: '5rem'}}>
                        <b>Team Members</b><br/><br/>
                        <i>
                            William Stark<br/>
                            Yicheng Feng<br/>
                            Regan Wei<br/>
                            Joyce Yin<br/>
                            Barry Shawn<br/>
                            Derek Zhang<br/>
                        </i>
                    </p>
                </div>
                <div className="work-body-image work-image-tile" sx={{backgroundImage: `url('/img/work/aspecta/aspecta-1.jpg')`, marginTop: '3rem', marginRight: 0, padding: '0', width: ['100%','100%','49%'], height: ['25rem','25rem','30rem'], backgroundSize: 'fit', backgroundRepeat: 'no-repeat', backgroundPositionX: '35%', backgroundPositionY: '60%'}}></div>
            </div>
            {/* <div sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], gap: '0', width: '100%', marginTop: '3em'}}>
                <div style={{margin: 0, padding: 0}}><img src="/img/work/aspecta/aspecta-3.jpg" sx={{ width: '100%', minHeight: '15em', borderRadius: ['.5em .5em 0em 0em', '.5em .5em 0em 0em', '.5em 0 0 .5em'], margin: 0, padding: 0 }}></img></div>
                <div style={{margin: 0, padding: 0}}><img src="/img/work/aspecta/aspecta-4.jpg" sx={{ width: '100%', minHeight: '15em', borderRadius: ['0em 0em .5em .5em', '0em 0em .5em .5em', '0 .5em .5em 0'], margin: 0, padding: 0 }}></img></div>
            </div> */}
            <div style={{ width: `calc(100% + 2em)`, }}>
                <div className="work-body-image work-image-tile" style={{ backgroundImage: `url('/img/work/aspecta/aspecta-5.jpg')` }}></div>
                <div className="work-body-image work-image-tile" style={{ backgroundImage: `url('/img/work/aspecta/aspecta-7.jpg')`, backgroundPositionX: '47.5%'}}></div>
            </div>
            <div className="work-body-image work-image-tile" style={{ backgroundImage: `url('/img/work/aspecta/aspecta-6.jpg')`, marginBottom: '8rem', width: '100%', marginRight: 0, height: ['20rem','20rem','50em'], backgroundPositionY: '40%' }}></div>
            
        </div>
    )
}

export default aspecta;