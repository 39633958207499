/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { EntypoGithubWithCircle, EntypoLinkedinWithCircle, EntypoMailWithCircle, EntypoInstagramWithCircle, EntypoSpotifyWithCircle } from "react-entypo"

function About(props) {
    return (
        <div className="About">
            <div className="about-wrapper">
                <h1>Curtis Zhou</h1>
                <div className="about-description">
                    <p>
                        Hi there. About section to be written.&nbsp;
                    </p>
                </div>
                <div style={{ fontSize: '3em' }}>
                    <a href="mailto:curtiszhou@outlook.com" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoMailWithCircle /></a>
                    <a href="https://instagram.com/curtiszhou_sh/" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoInstagramWithCircle /></a>
                    {/* <a href="https://github.com/TigerFeng37" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoGithubWithCircle /></a> */}
                    {/* <a href="https://linkedin.com/in/yicheng-feng/" sx={{ textDecoration: 'none', color: 'accent', marginRight: '.25em' }}><EntypoLinkedinWithCircle /></a> */}
                    <a href="https://open.spotify.com/artist/4ugDSzUCMDXCcPlvbH4nic" sx={{ textDecoration: 'none', color: 'accent' }}><EntypoSpotifyWithCircle /></a>
                </div>
                <h6 sx={{marginTop: '2em', color: 'gray'}}>Website built by w/ React.js & 💛</h6>
            </div>
        </div>
    )
}

export default About;